import { Dropdown } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../redux/actions/user';

export function Settings() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)

    async function onLogout() {
        dispatch(logout())
        localStorage.clear()
        navigate('/');
    }

    const handleRedirections = () => {
        if (user?.role === "venue") {
            return navigate("/venue/profile")
        } else if (user?.role === "host") {
            return navigate("/hostProfile")
        } else {
            return
        }
    }

    return (
        <a className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-[#005fa7]">
            <div className="flex w-full">
                <Dropdown
                    inline
                    label={
                        <>
                            <Cog6ToothIcon className="h-8 w-8" aria-hidden="true" />
                            <div className="w-full"><h1>Settings</h1></div>
                        </>
                    }
                >
                    <Dropdown.Header>
                        <span className="block truncate text-sm font-medium capitalize">
                            {user?.name || user?.fullName}
                            <div className='normal-case'>{user?.email}</div>
                        </span>
                    </Dropdown.Header>
                    <Dropdown.Item onClick={() => handleRedirections()}>Profile</Dropdown.Item>
                    {user?.docVerified !== "N" &&
                        <Dropdown.Item onClick={() => { navigate("/change-password") }}>Change Password</Dropdown.Item>
                    }
                    <Dropdown.Item onClick={onLogout} id="logoutBtn">Sign out</Dropdown.Item>
                </Dropdown>
            </div>
        </a>
    );
}
