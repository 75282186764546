import { useEffect, useState } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { emailPasswordSignIn } from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import { getRedirectURL, setRedirectURL } from '../../constants';
import Logo from "../../assets/images/logo.svg"
import google from "../../assets/images/google.svg"
import facebook from "../../assets/images/facebook.svg"
import instagram from "../../assets/images/Instagram.svg"
import linkedin from "../../assets/images/linkedin.svg"
import lock from "../../assets/images/Icons/lock.svg"
import message from "../../assets/images/Icons/message.svg"
import methodModel from '../../methods/methods';
import FormControl from '../../common/FormControl';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import ApiClient from '../../methods/api/apiClient';
import { useDispatch } from 'react-redux';
import { login_success } from '../../redux/actions/user';
import loader from '../../methods/loader';
import { toast } from 'react-toastify';

export const Signin = ({ setIsSignup, thirdPartySignInClicked }) => {
    const navigate = useNavigate();
    const [params] = useSearchParams();

    const dispatch = useDispatch()

    const [form, setForm] = useState({ email: "", password: "" })
    const inValidEmail = methodModel.emailvalidation(form?.email);
    const [submitted, setSubmitted] = useState(false)
    const [eye, setEye] = useState(false)

    useEffect(() => {
        const role = methodModel.getPrams("role")
        if (role) {
            setForm((prev) => ({ ...prev, role: role }))
        }
        if (localStorage.getItem("token")) {
            navigate("/");
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        loader(true)
        setSubmitted(true)
        const payload = {
            email: form?.email,
            password: form?.password,
        }
        const apiUrl = "users/login"
        ApiClient.post(apiUrl, payload).then((res) => {
            if (res.success) {
                if (res?.data?.doc_status === "rejected") {
                    toast.error("Sorry Admin Has Reject Your Profile.")
                    loader(false)
                    return
                }
                dispatch(login_success(res?.data))
                localStorage.setItem('token', res?.data?.access_token)
                if (res?.data?.role === "venue") {
                    navigate("/venue/profile")
                } else if (res?.data?.role === "host") {
                    navigate("/hostProfile")
                } else {
                    navigate("/home")
                }
            }
            loader(false)
        })
    };

    const handleRedirection = (url) => {
        if (url === "/auth" && form?.role === "venue") {
            return navigate("/providersignup")
        }
        if (form?.role) {
            navigate(`${url}?role=${form?.role}`)
        } else {
            navigate(url)
        }
    }

    return (
        // <div className="light">
        //     <section className="md:pt-8 pb-8 md:bg-gray-50 min-h-screen">
        //         <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
        //             <div className="w-full md:bg-white rounded-lg md:shadow md:mt-0 sm:max-w-lg xl:p-0">
        //                 <div className="px-6 space-y-4 md:space-y-6 sm:p-8">
        //                     <a
        //                         href="#"
        //                         className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        //                     >
        //                         <img
        //                             className="h-8 rounded p-1 mr-2"
        //                             src="/"
        //                             alt="logo"
        //                         />
        //                         blah
        //                     </a>

        //                     <h1 className="text-center text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
        //                         catch phrase
        //                     </h1>

        //                     <h2 className="text-center text-xl font-semibold tracking-tight text-gray-600 dark:text-gray-400">
        //                         Sign In
        //                     </h2>

        //                     <form
        //                         onSubmit={handleSubmit}
        //                         className="items-center space-y-4 md:space-y-6"
        //                         action="#"
        //                     >
        //                         <div>
        //                             <div className="flex flex-col items-center justify-center">
        //                                 <button
        //                                     onClick={() =>
        //                                         thirdPartySignInClicked(
        //                                             'google'
        //                                         )
        //                                     }
        //                                     type="button"
        //                                     className="justify-center w-3/4 text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2"
        //                                 >
        //                                     <svg
        //                                         className="w-4 h-4 mr-2"
        //                                         aria-hidden="true"
        //                                         xmlns="http://www.w3.org/2000/svg"
        //                                         fill="currentColor"
        //                                         viewBox="0 0 18 19"
        //                                     >
        //                                         <path
        //                                             fillRule="evenodd"
        //                                             d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
        //                                             clipRule="evenodd"
        //                                         />
        //                                     </svg>
        //                                     Sign in with Google
        //                                 </button>

        //                                 <button
        //                                     onClick={() =>
        //                                         thirdPartySignInClicked(
        //                                             'github'
        //                                         )
        //                                     }
        //                                     type="button"
        //                                     className="w-3/4 justify-center text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 mr-2 mb-2"
        //                                 >
        //                                     <svg
        //                                         className="w-4 h-4 mr-2"
        //                                         aria-hidden="true"
        //                                         xmlns="http://www.w3.org/2000/svg"
        //                                         fill="currentColor"
        //                                         viewBox="0 0 20 20"
        //                                     >
        //                                         <path
        //                                             fillRule="evenodd"
        //                                             d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z"
        //                                             clipRule="evenodd"
        //                                         />
        //                                     </svg>
        //                                     Sign in with Github
        //                                 </button>

        //                                 <div className="relative mt-8 mb-8 w-full">
        //                                     <div
        //                                         className="absolute inset-0 flex items-center"
        //                                         aria-hidden="true"
        //                                     >
        //                                         <div className="w-full border-t border-gray-200" />
        //                                     </div>
        //                                     <div className="relative flex justify-center text-sm font-medium leading-6">
        //                                         <span className="bg-white px-6 text-gray-900">
        //                                             Or continue with
        //                                         </span>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <label
        //                                 htmlFor="email"
        //                                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        //                             >
        //                                 Your email
        //                             </label>
        //                             <input
        //                                 type="email"
        //                                 onChange={e => setEmail(e.target.value)}
        //                                 name="email"
        //                                 id="email"
        //                                 className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        //                                 placeholder="name@company.com"
        //                                 required=""
        //                             />
        //                         </div>
        //                         <div>
        //                             <label
        //                                 htmlFor="password"
        //                                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        //                             >
        //                                 Password
        //                             </label>
        //                             <input
        //                                 type="password"
        //                                 onChange={e =>
        //                                     setPassword(e.target.value)
        //                                 }
        //                                 name="password"
        //                                 id="password"
        //                                 className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        //                                 placeholder="••••••••"
        //                                 required=""
        //                             />
        //                         </div>

        //                         <button
        //                             type="submit"
        //                             className={`w-full text-white ${
        //                                 isLoading
        //                                     ? 'bg-gray-400'
        //                                     : 'bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300'
        //                             } font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
        //                             disabled={isLoading}
        //                         >
        //                             {isLoading ? (
        //                                 <Spinner w={'4'} />
        //                             ) : (
        //                                 'Sign in'
        //                             )}
        //                         </button>

        //                         <p className="text-sm font-light text-gray-500 dark:text-gray-400">
        //                             Don't have an account yet?{' '}
        //                             <a
        //                                 onClick={() => setIsSignup(true)}
        //                                 className="cursor-pointer font-medium text-primary-600 hover:underline dark:text-primary-500"
        //                             >
        //                                 Create account
        //                             </a>
        //                         </p>

        //                         <Link
        //                             to="/auth/forgot-password"
        //                             className="text-sm font-light text-blue-500 text-center mt-2 cursor-pointer hover:underline dark:text-blue-400"
        //                         >
        //                             Forgot your password?
        //                         </Link>
        //                     </form>
        //                 </div>
        //             </div>
        //         </div>
        //     </section>
        // </div>

        <>
            <div className="w-full bg-cover bg-[url('assets/images/Signpagebg.png')] h-[100vh] flex justify-between md:flex-row flex-col" >
                <div className="md:max-w-[624px] w-full md:ps-[48px] pt-[15px] md:pt-[30px] px-7 md:px-0">
                    <Link to="/">
                        <img src={Logo} width={261.83} height={100} alt="" />
                    </Link>
                    <div className="flex flex-col gap-[30px] mt-[50px] md:mt-[100px]">
                        <h2 className="text-[50px] font-[700] leading-[60.95px] text-white">Join Marc and Discover Local Events</h2>
                        <p className="text-[18px] font-[400] leading-[27.54px] text-white">m ipsum dolor sit amet consectetur adipiscing elit Ut et mm ipsum dolor sit amet consectetur adipiscing elit Ut et massaassam ipsum dolor sit amet consectetur adipiscing elit Ut et massa m ipsum dolor sit amet consectetur adipiscing elit Ut et mas</p>
                    </div>
                </div>
                <div className="bg-white h-full w-full px-[50px] md:px-[107px] pb-[30px] md:pb-[118px] md:max-w-[652px] rounded-tl-[113.9px] md:rounded-tl-[204.9px] flex justify-center flex-col items-start mt-5 md:mt-0">
                    <form onSubmit={handleSubmit} autoComplete="one-time-code" className="w-full pt-[30px] md:pt-[134.71px]">
                        <h2 className="text-[#005AAB] text-[25.42px] leading-31 font-[800] text-left w-full capitalize">Sign In {form?.role && `As ${form?.role}`}</h2>
                        <div className="flex flex-col pt-[27px] w-full gap-3 md:gap-[21px]">
                            <div className="">
                                <FormControl
                                    type="text"
                                    name="email"
                                    placeholder="Enter Email"
                                    autoComplete="one-time-code"
                                    value={form?.email}
                                    onChange={(e) => setForm({ ...form, email: e })}
                                    required
                                />
                                {form?.email && !inValidEmail && submitted && (
                                    <div className="d-block text-red-600">Please enter valid email</div>
                                )}
                            </div>
                            <div className="relative flex mt-2">
                                <FormControl
                                    type={eye ? "text" : "password"}
                                    name="password"
                                    placeholder="Enter Password"
                                    autoComplete="one-time-code"
                                    value={form?.password}
                                    onChange={(e) => setForm({ ...form, password: e })}
                                    required
                                />
                                {!eye ?
                                    <IoEyeOffOutline size={20} onClick={e => setEye(true)} className="absolute bottom-2.5 right-3 cursor-pointer" />
                                    :
                                    <IoEyeOutline size={20} onClick={e => setEye(false)} className="absolute bottom-2.5 right-3 cursor-pointer" />
                                }
                            </div>
                        </div>
                        <div className="flex justify-end mt-6 cursor-pointer" onClick={e => handleRedirection('/auth/forgot-password')}>
                            <img className="me-1" src={lock} alt="lock-icon" />
                            <p className="text-[11.86px] font-[400] text-[#6B6B6B]">Forgot Password</p>
                        </div>
                        <div className="flex flex-col pt-[20px] w-full gap-4 md:gap-[35px]">
                            <button type='submit' className="text-[21.18px] text-[#FFFFFF] font-[700] bg-[#005AAB] rounded-[25.42px] py-2 cursor-pointer">
                                Sign In
                            </button>
                            {/* <div className="flex justify-center items-center gap-5">
                                <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                                <span className="text-[14.4px] font-[500] text-[#005AAB]">Or Sign Up With </span>
                                <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                            </div>
                            <div>
                                <div className="w-[40.67px] h-[40.67px] rounded-full flex justify-center items-center gap-2 md:gap-9 mx-auto border-[#005AAB]">
                                    <img className='cursor-pointer' src={google} onClick={() => thirdPartySignInClicked('google')} alt="google_icon" />
                                    <img className='cursor-pointer' src={facebook} onClick={() => thirdPartySignInClicked('google')} alt="facebook_icon" />
                                    <img className='cursor-pointer' src={linkedin} onClick={() => thirdPartySignInClicked('google')} alt="linkedin_icon" />
                                    <img className='cursor-pointer' src={instagram} onClick={() => thirdPartySignInClicked('google')} alt="instagram_icon" />
                                </div>
                            </div> */}
                            <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1]">
                                Don't have an account yet?{' '}
                                <span onClick={() => handleRedirection("/auth")} className="cursor-pointer font-medium text-[#3163b2] hover:underline dark:text-[#3163b2]">
                                    Create account
                                </span>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
