import { useEffect, useState } from "react";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import ApiClient from "../../methods/api/apiClient";
import Pagination from "react-js-pagination";
import loader from "../../methods/loader";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Location from '../../assets/images/Icons/location.svg';
import methodModel from "../../methods/methods";
import { MdOutlineClear } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { useSelector } from "react-redux";


const FollowingEvents = () => {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const [events, setEvents] = useState([])
    const [filters, setFilters] = useState({ page: 1, count: 12, search: "", date: "", status: "active" })
    const [total, setTotal] = useState(0)

    const [pageHeight, setPageHeight] = useState(window.innerHeight);
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const height = pageHeight - (pageWidth < 1024 ? 56 : 0) + 'px';

    useEffect(() => {
        getFollowingEvents()
    }, [])

    const getFollowingEvents = (f = {}) => {
        const filter = { ...filters, ...f, value: "follow", userId: user?._id || user?.id }
        loader(true)
        ApiClient.get(`event/listingByUserId`, filter).then(res => {
            if (res.success) {
                setEvents(res?.data)
                setTotal(res?.total)
            }
            loader(false)
        })
    }

    const handlePagination = (e) => {
        setFilters((prev) => ({ ...prev, page: e }))
        getFollowingEvents({ page: e })
    }

    const handleSearch = (e) => {
        e.preventDefault()
        getFollowingEvents(filters)
    }
    const searchInput = (e) => {
        if (e?.length !== 0) {
            setFilters({ ...filters, search: e })
        } else {
            setFilters({ ...filters, search: "" })
            getFollowingEvents({ search: "" })
        }
    }
    const clearSearch = () => {
        setFilters({ ...filters, search: "" })
        getFollowingEvents({ search: "" })
    }

    const unFollowEvent = (eventId) => {
        const payload = {
            id: eventId,
            type: "follow",
            userId: user?._id || user?.id,
        }
        loader(true)
        ApiClient.post(`event/follow`, payload).then(res => {
            if (res.success) {
                getFollowingEvents()
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="bg-[#D9D9D938]">
                <div className="bg-white w-full h-screen flex flex-row " style={{ maxHeight: height }}>
                    <DesktopSidebar />
                    <div id="mainPage" className={`w-full flex h-full overflow-auto lg:h-screen flex-col justify-center p-5`}>
                        <div className="w-full flex flex-col h-full">
                            <div className="">
                                <div className="w-full flex flex-row justify-between items-center">
                                    <h3 className="font-semibold text-[28px] text-[#1B1B25] leading-[42.84px] tracking-[3%]">Following Events</h3>
                                </div>
                                <div className="w-full flex flex-row justify-between items-center my-3">
                                    <form onSubmit={handleSearch} className="flex items-center gap-4">
                                        <div className="relative">
                                            <input type="text" value={filters?.search} onChange={e => searchInput(e.target.value)} placeholder="Search ..." className="bg-white w-full rounded-lg h-10 overflow-hidden px-2 border border-[#00000036]" />
                                            {filters?.search?.length > 0 && <MdOutlineClear size={20} onClick={() => clearSearch()} className="cursor-pointer absolute top-2.5 right-4" />}
                                        </div>
                                        <button type="submit" className="text-white bg-[#005AAB] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Search</button>
                                    </form>
                                </div>
                                <div className="grid grid-cols-1 gap-5 mt-5 md:mt-12 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                                    {events.map((item, index) => (
                                        <div key={index}>
                                            <div className="overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                                                <div className="relative">
                                                    <img className="object-cover w-full h-[146px] cursor-pointer" onClick={e => { navigate(`/EventDetails/${item?._id || item?.id}`) }} src={methodModel.noImg(item?.images?.[0])} alt="Article" />
                                                    <span size={25} onClick={e => unFollowEvent(item?.id || item?._id)} className="absolute top-3 right-3 flex items-center justify-center cursor-pointer text-[18px] text-nowrap font-semibold py-2 px-3 text-white border border-[#005AAB] bg-[#005AAB] rounded-[16px] md:inline-flex">Unfollow</span>
                                                </div>
                                                <div className="p-4 min-h-[115px] cursor-pointer" onClick={() => { navigate(`/EventDetails/${item?._id || item?.id}`) }}>
                                                    <h2 className="ellipises_heading font-[500] text-[18px] capitalize">
                                                        {item?.title}
                                                    </h2>
                                                    <p className="font-[400] text-[14px]">
                                                        {moment(item?.eventStartDate).format("DD MMM, YYYY hh:mm A")}
                                                    </p>
                                                    <p className="flex items-center gap-2 font-[400] text-[14px] mt-1">
                                                        <img src={Location} alt="location_icon" />
                                                        <span className='ellipsis_class text-[#585858] leading-[17px]'> {item?.location}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {events?.length === 0 ?
                                    <div className='text-center'>No Records Found</div>
                                    : null}
                                {total > filters?.count ?
                                    <div className="text-right pagination_div">
                                        <Pagination
                                            activePage={filters?.page}
                                            itemsCountPerPage={filters?.count}
                                            totalItemsCount={total}
                                            pageRangeDisplayed={5}
                                            hideFirstLastPages
                                            prevPageText="<"
                                            nextPageText=">"
                                            onChange={e => handlePagination(e)}
                                        />
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FollowingEvents