
import Modal from '../Components/Modal';
import google from '../../assets/images/google.svg';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/Instagram.svg';
import linkedin from '../../assets/images/linkedin.svg';
import lock from '../../assets/images/Icons/lock.svg';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FormControl from '../../common/FormControl';
import ApiClient from '../../methods/api/apiClient';
import { useDispatch } from 'react-redux';
import { login_success } from '../../redux/actions/user';
import methodModel from '../../methods/methods';
import loader from '../../methods/loader';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';



const SigninModal = ({ isModalOpen, closeModal, setIsSignup, thirdPartySignInClicked }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form, setForm] = useState({ email: "", password: "" })
    const inValidEmail = methodModel.emailvalidation(form?.email);
    const [submitted, setSubmitted] = useState(false)
    const [eye, setEye] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true)
        if (!inValidEmail) return;
        const payload = {
            email: form?.email,
            password: form?.password,
        }
        loader(true)
        const apiUrl = "users/login"
        ApiClient.post(apiUrl, payload).then((res) => {
            if (res.success) {
                if (res?.data?.doc_status === "rejected") {
                    toast.error("Sorry Admin Has Reject Your Profile.")
                    loader(false)
                    return
                }
                dispatch(login_success(res?.data))
                localStorage.setItem('token', res?.data?.access_token)
                closeModal()
            }
            loader(false)
        })
    };

    const handleRedirection = (url) => {
        // if (url === "/auth" && form?.role === "venue") {
        return navigate("/providersignup")
        // }
        // if (form?.role) {
        //     navigate(`${url}?role=${form?.role}`)
        // } else {
        //     navigate(url)
        // }
    }

    return (
        <>
            <Modal open={isModalOpen} onClose={closeModal} title="My Modal">
                <form
                    onSubmit={handleSubmit}
                    className="w-full py-[30px] rounded-[16px]"
                >
                    <h2 className="text-[#005AAB]   text-[25.42px] leading-31 font-[800] text-left w-full">
                        Sign In
                    </h2>
                    <div className="flex flex-col pt-[27px] w-full gap-3 md:gap-[21px]">
                        <div className="">
                            <FormControl
                                type="text"
                                name="email"
                                placeholder="Enter Email"
                                autoComplete="one-time-code"
                                value={form?.email}
                                onChange={(e) => setForm({ ...form, email: e })}
                                required
                            />
                            {form?.email && !inValidEmail && submitted && (
                                <div className="d-block text-red-600">Please enter valid email</div>
                            )}
                        </div>
                        <div className="relative flex mt-2">
                            <FormControl
                                type={eye ? "text" : "password"}
                                name="password"
                                placeholder="Enter Password"
                                autoComplete="one-time-code"
                                value={form?.password}
                                onChange={(e) => setForm({ ...form, password: e })}
                                required
                            />
                            {!eye ?
                                <IoEyeOffOutline size={20} onClick={e => setEye(true)} className="absolute bottom-2.5 right-3 cursor-pointer" />
                                :
                                <IoEyeOutline size={20} onClick={e => setEye(false)} className="absolute bottom-2.5 right-3 cursor-pointer" />
                            }
                        </div>
                    </div>
                    <div className="flex justify-end mt-6 cursor-pointer" onClick={e => handleRedirection('/auth/forgot-password')}>
                        <img className="me-1" src={lock} alt="lock-icon" />
                        <p className="text-[11.86px] font-[400] text-[#6B6B6B]">Forgot Password</p>
                    </div>

                    <div className="flex flex-col pt-[20px] w-full gap-4 md:gap-[35px]">
                        <button type='submit' className="text-[21.18px] text-[#FFFFFF] font-[700] bg-[#005AAB] rounded-[25.42px] py-2 cursor-pointer">
                            Sign In
                        </button>
                        {/* <div className="flex justify-center items-center gap-5">
                            <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                            <span className="text-[14.4px] font-[500] text-[#005AAB]">Or Sign Up With </span>
                            <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                        </div> */}
                        {/* <div>
                            <div className="w-[40.67px] h-[40.67px] rounded-full flex justify-center items-center gap-2 md:gap-9 mx-auto border-[#005AAB]">
                                <img className='cursor-pointer' src={google} onClick={() => thirdPartySignInClicked('google')} alt="google_icon" />
                                <img className='cursor-pointer' src={facebook} onClick={() => thirdPartySignInClicked('google')} alt="facebook_icon" />
                                <img className='cursor-pointer' src={linkedin} onClick={() => thirdPartySignInClicked('google')} alt="linkedin_icon" />
                                <img className='cursor-pointer' src={instagram} onClick={() => thirdPartySignInClicked('google')} alt="instagram_icon" />
                            </div>
                        </div> */}
                        <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1]">
                            Don't have an account yet?{' '}
                            <span onClick={() => handleRedirection("/auth")} className="cursor-pointer font-medium text-[#3163b2] hover:underline dark:text-[#3163b2]">
                                Create account
                            </span>
                        </p>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default SigninModal