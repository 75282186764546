import { redirect, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FolderIcon } from '@heroicons/react/24/solid';
import { MdDashboard } from "react-icons/md";
import { FaHeart } from 'react-icons/fa';
import { useSelector } from 'react-redux';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const Item = ({ closeSidebar, id }) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user)
    const { pathname } = useLocation();

    const redirect = (link) => {
        navigate(link);
        // if (closeSidebar) {
        //     closeSidebar();
        // }
    };

    const activeLink = (path) => {
        return pathname.includes(path)
    }

    return (
        <>
            {user?.docVerified === "N" ? null :
                <>
                    <p onClick={() => redirect("/home")} className={classNames('text-[#005fa7] hover:bg-[#e5edfa] hover:!text-[#3b7ffa]', 'group flex items-center gap-[12px] rounded-md px-4 py-2 text-sm font-[500] cursor-pointer', activeLink("home") ? 'bg-[#e5edfa] !text-[#3b7ffa]' : '')}>
                        <MdDashboard className="text-[#005fa7] w-[30px] h-[30px] p-[6px] rounded-[50%] bg-white" aria-hidden="true" />
                        <span className="truncate text-inherit leading-none sidebar_text">Dashboard</span>
                    </p>
                    <p onClick={() => redirect('/wishlist')} className={classNames('text-[#005fa7] hover:bg-[#e5edfa] hover:!text-[#3b7ffa]', 'group flex items-center gap-[12px] rounded-md px-4 py-2 text-sm font-[500] cursor-pointer', activeLink("wishlist") ? 'bg-[#e5edfa] !text-[#3b7ffa]' : '')}>
                        <FaHeart className="text-[#005fa7] w-[30px] h-[30px] p-[6px] rounded-[50%] bg-white" aria-hidden="true" />
                        <span className="truncate text-inherit leading-none sidebar_text">Wishlist</span>
                    </p>
                    <p onClick={() => redirect('/following/events')} className={classNames('text-[#005fa7] hover:bg-[#e5edfa] hover:!text-[#3b7ffa]', 'group flex items-center gap-[12px] rounded-md px-4 py-2 text-sm font-[500] cursor-pointer', activeLink("following/events") ? 'bg-[#e5edfa] !text-[#3b7ffa]' : '')}>
                        <FaHeart className="text-[#005fa7] w-[30px] h-[30px] p-[6px] rounded-[50%] bg-white" aria-hidden="true" />
                        <span className="truncate text-inherit leading-none sidebar_text">Following Events</span>
                    </p>
                </>
            }
        </>
    );
};
