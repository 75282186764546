import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Signup } from './components/Auth/signup';
// import Home from './components/Home';
// import { LandingPage } from './components/Landing';
// import { ApolloProvider } from '@apollo/client';
// import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
// import { SessionAuth } from 'supertokens-auth-react/recipe/session';
// import { ThirdpartyEmailPasswordComponentsOverrideProvider } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
// import { client } from './api/client';
// import { EmailPasswordHeadersOverride, SuperTokensConfig } from './auth_config';
// import { LoadingProvider } from './context-providers/Loading';
// import { useSessionContext } from 'supertokens-auth-react/recipe/session';
// import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
// import { PreBuiltUIList } from './auth_config';
// import { useEffect } from 'react';
// import { CtaSection2 } from './components/Components';
import { Forgot } from './components/Auth/forgot';
import { Reset } from './components/Auth/reset';
import { Toaster } from 'sonner';
import { LandingPage } from './components/Landing';
import Home from './components/Home';
import Landing2 from './components/Components/Landing/landing';
import ProviderSignIn from './components/Auth/providerSignin';
import ProviderSignUp from './components/Auth/providerSignup';
import Events from './components/Events';
import EventDetails from './components/EventDetail';
import TicketPage from './components/Ticket';
import PaymentPage from './components/Payment';
import ConfirmationPage from './components/Confirmation';
import PaymentC from './components/Payment/paymentC';
import FilterPage from './components/Filters';
import CreateEvent from './components/CreateEvent';
import HostProfile from './components/HostProfile';
import { Signin } from './components/Auth/signin';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import loader from '../src/assets/images/loader.gif';
import ChangePassword from './components/Auth/ChangePassword';
import VenueHome from './components/VenueHome';
import VenueProfile from './components/VenueProfile';
import AllEvents from './components/Events/AllEvents';
import AllVenues from './components/Venues/AllVenues';
import AllUpcomingEvents from './components/Events/AllUpcomingEvents';
import Wishlist from './components/Wishlist';
import FollowingEvents from './components/Events/FollowingEvents';

// SuperTokens.init(SuperTokensConfig);

// const SessionWrapper = ({ children }) => {
//     const sessionContext = useSessionContext();

//     useEffect(() => {
//         if (sessionContext?.accessTokenPayload?.email) {
//             // console.log(
//             // 	"Identifying user - " + sessionContext.accessTokenPayload.email
//             // );
//             // posthog.identify(
//             //     sessionContext.userId,
//             //     { email: sessionContext.accessTokenPayload.email } // optional: set additional user properties
//             // );
//         }
//     }, [sessionContext.accessTokenPayload]);

//     return sessionContext.loading ? null : <>{children}</>;
// };

function App() {
    return (
        <>
            {/* <SuperTokensWrapper> */}
            <Toaster position="top-center" />
            {/* <ThirdpartyEmailPasswordComponentsOverrideProvider
                    components={{
                        EmailPasswordSignInHeader_Override:
                            EmailPasswordHeadersOverride,
                        EmailPasswordSignUpHeader_Override:
                            EmailPasswordHeadersOverride
                    }}
                > */}
            {/* <ApolloProvider client={client}> */}
            <Router>
                {/* <div className="flex flex-col justify-center"> */}
                <Routes>
                    {/* This shows the login UI on "/auth" route */}
                    {/* {getSuperTokensRoutesForReactRouterDom(
                                    require('react-router-dom'),
                                    PreBuiltUIList
                                )} */}
                    <Route path="/signin" element={<Signin />} />
                    <Route path="/auth/*" element={<Signup />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/auth/reset-password" element={<Reset />} />
                    <Route path="/auth/forgot-password" element={<Forgot />} />
                    <Route
                        path="/"
                        element={
                            // <SessionWrapper>
                            <LandingPage />
                            // {/* </SessionWrapper> */}
                        } />
                    <Route
                        path="/home"
                        element={
                            // <SessionAuth>
                            <Home />
                            // </SessionAuth>
                        }
                    >
                    </Route>
                    <Route path="/wishlist" element={<Wishlist />}></Route>
                    <Route path="/following/events" element={<FollowingEvents />}></Route>
                    <Route path="/ProviderLanding" element={<Landing2 />}></Route>
                    <Route path="/providersignin" element={<ProviderSignIn />}></Route>
                    <Route path="/providersignup" element={<ProviderSignUp />}></Route>
                    <Route path="/providersignup/:id" element={<ProviderSignUp />}></Route> {/* For Claim Venue */}
                    <Route path="/all/venues" element={<AllVenues />}></Route>
                    <Route path="/all/upcoming/events" element={<AllUpcomingEvents />}></Route>
                    <Route path="/all/events" element={<AllEvents />}></Route>
                    <Route path="/Event" element={<Events />}></Route>
                    <Route path="/EventFilter/:id" element={<FilterPage />}></Route>
                    <Route path="/EventDetails/:id" element={<EventDetails />}></Route>
                    <Route path="/Ticket" element={<TicketPage />}></Route>
                    <Route path="/Payment" element={<PaymentPage />}></Route>
                    <Route path="/Confirmation" element={<ConfirmationPage />}></Route>
                    <Route path="/PaymentConfirm" element={<PaymentC />}></Route>
                    <Route path="/hostProfile" element={<HostProfile />}></Route>
                    <Route path="/venue/profile" element={<VenueProfile />}></Route>
                    <Route path="/CreateEvent" element={<CreateEvent />}></Route>
                </Routes>
            </Router>
            {/* </ApolloProvider> */}
            {/* </ThirdpartyEmailPasswordComponentsOverrideProvider> */}
            {/* </SuperTokensWrapper> */}
            <div id="loader" className="hidden fixed inset-0 flex items-center justify-center z-[99999] backdrop-blur-sm h-full w-full">
                <img src={loader} alt="logo" width="50px" />
            </div>
            <ToastContainer position="top-right" />
        </>
    );
}

export default App;
