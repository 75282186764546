import React, { useEffect, useState } from 'react';
import { NavigationBar } from '../Landing/NavigationBar';
import Logo from '../../assets/images/logo-nav.svg';
import HImg1 from '../../assets/images/hot-img1.png';
import HImg2 from '../../assets/images/hot-img2.png';
import CImg1 from '../../assets/images/cont-img1.png';
import CImg2 from '../../assets/images/cont-img2.png';
import CImg3 from '../../assets/images/cont-img3.png';
import CImg4 from '../../assets/images/cont-img4.png';
import Footer from '../Footer';
import Location from '../../assets/images/Icons/location.svg';
import left from '../../assets/images/Icons/arrow-left.svg';
import right from '../../assets/images/Icons/arrow-right.svg';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css/navigation';
import 'swiper/css';
import { Link } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import DatePicker from 'react-datepicker';
import { IoIosSearch, IoMdClose } from 'react-icons/io';
import moment from "moment"
import methodModel from '../../methods/methods';
import loader from '../../methods/loader';

const Concert = [
    {
        img: CImg1
    },
    {
        img: CImg2
    },
    {
        img: CImg3
    },
    {
        img: CImg4
    }
];

const Events = () => {
    const swiperRef = React.useRef(null)
    const [events, setEvents] = useState([])
    const [venues, setVenues] = useState([])
    const [filters, setFilters] = useState({ search: "", date: "", status: "active" })

    useEffect(() => {
        getEvents()
    }, [])

    const getEvents = (f = {}) => {
        const filter = { ...filters, ...f, value: "upcoming" }
        loader(true)
        ApiClient.get("event/listing", filter).then(res => {
            if (res.success) {
                setEvents(res?.data?.filter((item, index) => {
                    if (index < 4) return ({ ...item })
                }))
            }
            loader(false)
        })
    }

    const getVenues = () => {
        loader(true)
        ApiClient.get("users/listing?status=active").then(res => {
            if (res.success) {
                setVenues(res?.data?.filter((item, index) => {
                    if (index < 4) return ({ ...item })
                }))
            }
            loader(false)
        })
    }

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    }

    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    }

    const clearSearch = () => {
        const filter = { search: "", date: "", status: "active" }
        setFilters(filter)
        getEvents(filter)
    }

    const handleSearch = () => {
        let eventDate = filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : ""
        getEvents({ ...filters, date: String(eventDate) })
    }

    return (
        <>
            <div className="bg-[#D9D9D938]">
                <NavigationBar />
                <div className="w-[90%] mx-auto flex flex-col items-center justify-center py-[80px]">
                    <h2 className="font-[700] text-[50px] text-[#E1B524] text-center leading-[54px]">
                        Exclusive events, priceless moments
                    </h2>
                    <div className="w-full flex md:flex-row flex-col justify-center items-center gap-2 pt-[60px]">
                        <div className="max-w-[600px] w-full flex justify-between rounded-[30px] border border-[1px] !border-[#6a737f] py-2">
                            <input
                                type="text"
                                value={filters?.search}
                                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                className="bg-transparent flex-1 text-[#AEAEAE] outline-none border-0 focus:outline-none border-r focus:ring-0"
                                placeholder="Search by events, name, location, and more"
                            />
                            <DatePicker
                                selected={filters?.date}
                                onChange={(date) => setFilters({ ...filters, date: date })}
                                className="bg-transparent border-0 text-[#AEAEAE] outline-none focus:outline-none focus:ring-0 w-[180px]"
                                placeholderText='mm/dd/yyyy'
                                minDate={new Date()}
                            />
                        </div>
                        {filters?.search || filters?.date
                            ?
                            <button type='button' onClick={e => clearSearch()} className="flex items-center justify-center text-[18px] text-nowrap font-semibold py-3 px-5 text-white hover:text-[#005AAB] border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">
                                <IoMdClose className='mr-1' /> Clear
                            </button>
                            : null
                        }
                        <button type='button' onClick={e => handleSearch()} className="flex items-center justify-center text-[18px] text-nowrap font-semibold py-3 px-5 text-white hover:text-[#005AAB] border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">
                            <IoIosSearch className='mr-1' /> Search
                        </button>
                    </div>
                </div>
            </div>
            <div className="upcoming py-[40px]">
                <div className="w-[85%] mx-auto">
                    <div className="w-full flex flex-row justify-between items-center">
                        <h3 className="font-semibold text-[28px] text-[#1B1B25] leading-[42.84px] tracking-[3%]">
                            Upcoming Events
                        </h3>
                        <Link to="/all/upcoming/events">
                            <button className="font-semibold text-base text-[#005AAB] leading-[23.4px] tracking-[3%] font-open-sans">
                                View All {'>'}
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="w-[85%] mx-auto grid grid-cols-1 gap-5 mt-5 md:mt-12 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                    {events.map((item, index) => (
                        <Link to={`/EventDetails/${item?._id || item?.id}`} key={index}>
                            <div className=" overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                                <img className="object-cover w-full h-[146px]" src={methodModel.noImg(item?.images?.[0])} alt="event_image" />
                                <div className="p-4 min-h-[115px]">
                                    <h2 className="ellipises_heading font-[500] text-[18px] capitalize">
                                        {item?.title}
                                    </h2>
                                    <p className="font-[400] text-[14px]">
                                        {moment(item?.eventStartDate).format("DD MMM, YYYY hh:mm A")}
                                    </p>
                                    <p className="flex items-center gap-2 font-[400] text-[14px] mt-1">
                                        <img src={Location} alt="location_icon" />
                                        <span className='ellipsis_class text-[#585858] leading-[17px]'> {item?.location} </span>
                                    </p>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
                {events?.length === 0 ?
                    <div className='text-center'>No Records Found</div>
                    : null}
            </div>
            <div className="hot-offers py-[40px]">
                <div className="w-[85%] mx-auto">
                    <div className="w-full flex flex-row justify-between items-center">
                        <h3 className="font-semibold text-[28px] text-[#1B1B25] leading-[42.84px] tracking-[3%]">
                            Hot Offers
                        </h3>
                        <button className="font-semibold text-base text-[#005AAB] leading-[23.4px] tracking-[3%] font-open-sans">
                            View All {'>'}
                        </button>
                    </div>
                </div>
                <div className="w-[85%] mx-auto grid grid-cols-1 gap-8 mt-5 md:mt-12 md:grid-cols-2">
                    <div>
                        <img
                            className="w-full h-full object-contain"
                            src={HImg1}
                            alt=""
                        />
                    </div>
                    <div>
                        <img
                            className="w-full h-full object-contain"
                            src={HImg2}
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-end py-[40px]">
                <div className="w-[92.5%]">
                    <h3 className="text-[#000000] text-[28px] font-[600]">
                        Event Organizers{' '}
                    </h3>
                    <p className="text-[#000000] text-[18px] font-[400]">
                        m ipsum dolor sit amet consectetur adipiscing elit Ut et
                        massa
                    </p>
                    <div className="pt-[40px]">
                        <Swiper
                            className="mySwiper"
                            loop={true}
                            slidesPerView={1}
                            spaceBetween={10}
                            navigation={false}
                            modules={[Navigation]}
                            breakpoints={{
                                1100: {
                                    slidesPerView: 4.5,
                                    spaceBetween: 30
                                }
                            }}
                            ref={swiperRef}
                        >
                            <SwiperSlide>
                                <div className="bg-[#F7F7F7] py-[23px] rounded-[16px] shadow-md flex flex-col justify-center items-center gap-[25px]">
                                    <img
                                        className="w-[147px] w-[50px]"
                                        src={Logo}
                                        alt=""
                                    />
                                    <h3 className="font-montserrat text-xl font-semibold leading-tight tracking-wide text-left text-[#1B1B25]">
                                        GBeeTechive USA
                                    </h3>
                                    <p className="font-open-sans text-base font-normal leading-6 tracking-wide text-left text-black">
                                        1444 Followers
                                    </p>
                                    <button className="py-2 w-[139px] gap-2 rounded-lg bg-[#005AAB] text-white">
                                        follow
                                    </button>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bg-[#F7F7F7] py-[23px] rounded-[16px] shadow-md flex flex-col justify-center items-center gap-[25px]">
                                    <img
                                        className="w-[147px] w-[50px]"
                                        src={Logo}
                                        alt=""
                                    />
                                    <h3 className="font-montserrat text-xl font-semibold leading-tight tracking-wide text-left text-[#1B1B25]">
                                        GBeeTechive USA
                                    </h3>
                                    <p className="font-open-sans text-base font-normal leading-6 tracking-wide text-left text-black">
                                        1444 Followers
                                    </p>
                                    <button className="py-2 w-[139px] gap-2 rounded-lg bg-[#005AAB] text-white">
                                        follow
                                    </button>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bg-[#F7F7F7] py-[23px] rounded-[16px] shadow-md flex flex-col justify-center items-center gap-[25px]">
                                    <img
                                        className="w-[147px] w-[50px]"
                                        src={Logo}
                                        alt=""
                                    />
                                    <h3 className="font-montserrat text-xl font-semibold leading-tight tracking-wide text-left text-[#1B1B25]">
                                        GBeeTechive USA
                                    </h3>
                                    <p className="font-open-sans text-base font-normal leading-6 tracking-wide text-left text-black">
                                        1444 Followers
                                    </p>
                                    <button className="py-2 w-[139px] gap-2 rounded-lg bg-[#005AAB] text-white">
                                        follow
                                    </button>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bg-[#F7F7F7] py-[23px] rounded-[16px] shadow-md flex flex-col justify-center items-center gap-[25px]">
                                    <img
                                        className="w-[147px] w-[50px]"
                                        src={Logo}
                                        alt=""
                                    />
                                    <h3 className="font-montserrat text-xl font-semibold leading-tight tracking-wide text-left text-[#1B1B25]">
                                        GBeeTechive USA
                                    </h3>
                                    <p className="font-open-sans text-base font-normal leading-6 tracking-wide text-left text-black">
                                        1444 Followers
                                    </p>
                                    <button className="py-2 w-[139px] gap-2 rounded-lg bg-[#005AAB] text-white">
                                        follow
                                    </button>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bg-[#F7F7F7] py-[23px] rounded-[16px] shadow-md flex flex-col justify-center items-center gap-[25px]">
                                    <img
                                        className="w-[147px] w-[50px]"
                                        src={Logo}
                                        alt=""
                                    />
                                    <h3 className="font-montserrat text-xl font-semibold leading-tight tracking-wide text-left text-[#1B1B25]">
                                        GBeeTechive USA
                                    </h3>
                                    <p className="font-open-sans text-base font-normal leading-6 tracking-wide text-left text-black">
                                        1444 Followers
                                    </p>
                                    <button className="py-2 w-[139px] gap-2 rounded-lg bg-[#005AAB] text-white">
                                        follow
                                    </button>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bg-[#F7F7F7] py-[23px] rounded-[16px] shadow-md flex flex-col justify-center items-center gap-[25px]">
                                    <img
                                        className="w-[147px] w-[50px]"
                                        src={Logo}
                                        alt=""
                                    />
                                    <h3 className="font-montserrat text-xl font-semibold leading-tight tracking-wide text-left text-[#1B1B25]">
                                        GBeeTechive USA
                                    </h3>
                                    <p className="font-open-sans text-base font-normal leading-6 tracking-wide text-left text-black">
                                        1444 Followers
                                    </p>
                                    <button className="py-2 w-[139px] gap-2 rounded-lg bg-[#005AAB] text-white">
                                        follow
                                    </button>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bg-[#F7F7F7] py-[23px] rounded-[16px] shadow-md flex flex-col justify-center items-center gap-[25px]">
                                    <img
                                        className="w-[147px] w-[50px]"
                                        src={Logo}
                                        alt=""
                                    />
                                    <h3 className="font-montserrat text-xl font-semibold leading-tight tracking-wide text-left text-[#1B1B25]">
                                        GBeeTechive USA
                                    </h3>
                                    <p className="font-open-sans text-base font-normal leading-6 tracking-wide text-left text-black">
                                        1444 Followers
                                    </p>
                                    <button className="py-2 w-[139px] gap-2 rounded-lg bg-[#005AAB] text-white">
                                        follow
                                    </button>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bg-[#F7F7F7] py-[23px] rounded-[16px] shadow-md flex flex-col justify-center items-center gap-[25px]">
                                    <img
                                        className="w-[147px] w-[50px]"
                                        src={Logo}
                                        alt=""
                                    />
                                    <h3 className="font-montserrat text-xl font-semibold leading-tight tracking-wide text-left text-[#1B1B25]">
                                        GBeeTechive USA
                                    </h3>
                                    <p className="font-open-sans text-base font-normal leading-6 tracking-wide text-left text-black">
                                        1444 Followers
                                    </p>
                                    <button className="py-2 w-[139px] gap-2 rounded-lg bg-[#005AAB] text-white">
                                        follow
                                    </button>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bg-[#F7F7F7] py-[23px] rounded-[16px] shadow-md flex flex-col justify-center items-center gap-[25px]">
                                    <img
                                        className="w-[147px] w-[50px]"
                                        src={Logo}
                                        alt=""
                                    />
                                    <h3 className="font-montserrat text-xl font-semibold leading-tight tracking-wide text-left text-[#1B1B25]">
                                        GBeeTechive USA
                                    </h3>
                                    <p className="font-open-sans text-base font-normal leading-6 tracking-wide text-left text-black">
                                        1444 Followers
                                    </p>
                                    <button className="py-2 w-[139px] gap-2 rounded-lg bg-[#005AAB] text-white">
                                        follow
                                    </button>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="bg-[#F7F7F7] py-[23px] rounded-[16px] shadow-md flex flex-col justify-center items-center gap-[25px]">
                                    <img
                                        className="w-[147px] w-[50px]"
                                        src={Logo}
                                        alt=""
                                    />
                                    <h3 className="font-montserrat text-xl font-semibold leading-tight tracking-wide text-left text-[#1B1B25]">
                                        GBeeTechive USA
                                    </h3>
                                    <p className="font-open-sans text-base font-normal leading-6 tracking-wide text-left text-black">
                                        1444 Followers
                                    </p>
                                    <button className="py-2 w-[139px] gap-2 rounded-lg bg-[#005AAB] text-white">
                                        follow
                                    </button>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="mt-[38px] flex justify-start">
                        <div className="relative w-[100px] flex flex-row-reverse justify-start">
                            <button
                                className="swiper-button-prev after:hidden"
                                onClick={handlePrev}
                            >
                                <img src={left} alt="" />
                            </button>
                            <button
                                className="swiper-button-next after:hidden"
                                onClick={handleNext}
                            >
                                <img src={right} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="concert py-[40px] bg-[#D9D9D938]">
                <div className="w-[85%] mx-auto">
                    <div className="w-full flex flex-row justify-between items-center">
                        <h3 className="font-semibold text-[28px] text-[#1B1B25] leading-[42.84px] tracking-[3%]">
                            Concerts
                        </h3>
                        <button className="font-semibold text-base text-[#005AAB] leading-[23.4px] tracking-[3%] font-open-sans">
                            View All {'>'}
                        </button>
                    </div>
                </div>
                <div className="w-[85%] mx-auto grid grid-cols-1 gap-5 mt-5 md:mt-12 md:grid-cols-4">
                    {Concert.map(cards => (
                        <img
                            className="object-cover w-full"
                            src={cards.img}
                            alt="Article"
                        />
                    ))}
                </div>
            </div>
            <section className="bg-white dark:bg-gray-900 max-w-screen-xl mx-auto">
                <div className=" px-6 py-10 mx-auto">
                    <h3 className="text-[#000000] text-[28px] font-[600]">
                        Trending Searches
                    </h3>
                    <p className="text-[#000000] text-[18px] font-[400]">
                        m ipsum dolor sit amet consectetur adipiscing elit Ut et
                        massa
                    </p>
                    <div className="grid grid-cols-1 gap-8 mt-5 md:mt-10 md:grid-cols-8">
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                        <p className="font-semibold text-base text-[#9AC3FF] leading-[23.4px] tracking-[0.03em] text-left font-open-sans">
                            Lorem Ispum
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default Events;
