import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import { NavigationBar } from "../Landing/NavigationBar"
import ApiClient from "../../methods/api/apiClient";
import { IoIosSearch, IoMdClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import methodModel from "../../methods/methods";
import Location from '../../assets/images/Icons/location.svg';
import Pagination from "react-js-pagination";

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon, PencilIcon } from '@heroicons/react/16/solid'
import { HiDotsVertical } from "react-icons/hi";

const AllVenues = () => {
    const navigate = useNavigate()
    const [venues, setVenues] = useState([])
    const [filters, setFilters] = useState({ page: 1, count: 12, search: "", status: "active" })
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getAllVenues()
    }, [])

    const getAllVenues = (f = {}) => {
        const filter = { ...filters, ...f }
        loader(true)
        ApiClient.get("users/listing", filter).then(res => {
            if (res.success) {
                setVenues(res?.data)
                setTotal(res?.total)
            }
            loader(false)
        })
    }

    const clearSearch = () => {
        const filter = { search: "", status: "active" }
        setFilters(filter)
        getAllVenues(filter)
    }
    const handleSearch = () => {
        getAllVenues(filters)
    }

    const handlePagination = (e) => {
        setFilters((prev) => ({ ...prev, page: e }))
        getAllVenues({ page: e })
    }

    const handleClaimVenue = (id) => {
        navigate(`/providersignup/${id}`)
    }

    return (
        <>
            <div className="bg-[#D9D9D938]">
                <NavigationBar />
                <div className="w-[90%] mx-auto flex flex-col items-center justify-center py-[80px]">
                    <h2 className="font-[700] text-[50px] text-[#E1B524] text-center leading-[54px]">
                        Exclusive venues, priceless moments
                    </h2>
                    <div className="w-full flex md:flex-row flex-col justify-center items-center gap-2 pt-[60px]">
                        <div className="max-w-[600px] w-full flex justify-between rounded-[30px] border-[1px] !border-[#6a737f] py-2">
                            <input
                                type="text"
                                value={filters?.search}
                                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                className="bg-transparent flex-1 text-[#AEAEAE] outline-none border-0 focus:outline-none focus:ring-0"
                                placeholder="Search by venue name and location"
                            />
                        </div>
                        {filters?.search
                            ?
                            <button type='button' onClick={e => clearSearch()} className="flex items-center justify-center text-[18px] text-nowrap font-semibold py-3 px-5 text-white hover:text-[#005AAB] border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">
                                <IoMdClose className='mr-1' /> Clear
                            </button>
                            : null
                        }
                        <button type='button' onClick={e => handleSearch()} className="flex items-center justify-center text-[18px] text-nowrap font-semibold py-3 px-5 text-white hover:text-[#005AAB] border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">
                            <IoIosSearch className='mr-1' /> Search
                        </button>
                    </div>
                </div>
                <div className="upcoming py-[40px]">
                    <div className="w-[85%] mx-auto">
                        <div className="w-full flex flex-row justify-between items-center">
                            <h3 className="font-semibold text-[28px] text-[#1B1B25] leading-[42.84px] tracking-[3%]">
                                All Venues
                            </h3>
                        </div>
                    </div>
                    <div className="w-[85%] mx-auto grid grid-cols-1 gap-5 mt-5 md:mt-12 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                        {venues.map((item, index) => (
                            <div key={index}>
                                <div className="overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                                    <div className="relative">
                                        <img onClick={e => { navigate(`/EventFilter/${item?._id || item?.id}`) }} className="cursor-pointer object-cover w-full h-[146px]" src={methodModel.noImg(item?.images?.[0])} alt="Article" />
                                        <div className="absolute top-3 right-3">
                                            <Menu>
                                                <MenuButton className="text-white">
                                                    <HiDotsVertical />
                                                </MenuButton>
                                                <MenuItems
                                                    transition
                                                    anchor="bottom end"
                                                    className="w-52 origin-top-right rounded-xl p-1 text-sm/6 text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                                                >
                                                    <MenuItem>
                                                        <button onClick={()=>handleClaimVenue(item?._id || item?.id)} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 bg-white">
                                                            <PencilIcon className="size-4 fill-white/30" /> Claim Venue
                                                        </button>
                                                    </MenuItem>
                                                </MenuItems>
                                            </Menu>
                                        </div>
                                    </div>
                                    <div onClick={e => { navigate(`/EventFilter/${item?._id || item?.id}`) }} className="cursor-pointer p-4 min-h-[146px]">
                                        <h2 className="ellipises_heading font-[500] text-[18px] capitalize">
                                            {item?.venue_name}
                                        </h2>
                                        <p className="font-[400] text-[14px] my-3 flex flex-wrap gap-1">
                                            {item?.amenities?.slice(0, 2)?.map((itm, i) => {
                                                return <span className="px-3 py-[5px] bg-[#005ea7b5] rounded-full mr-2 text-white text-[12px]" key={i}>{itm?.title}</span>
                                            })}
                                        </p>
                                        <p className="flex gap-2 font-[400] text-[14px]">
                                            <img src={Location} alt="location_icon" />
                                            <span className='ellipsis_class text-[#585858] leading-[17px]'> {item?.location}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {venues?.length === 0 ?
                        <div className='text-center'>No Records Found</div>
                        : null}
                    {total > filters?.count ?
                        <div className="pagination_div w-[85%] text-right mx-auto">
                            <Pagination
                                activePage={filters?.page}
                                itemsCountPerPage={filters?.count}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                hideFirstLastPages
                                prevPageText="<"
                                nextPageText=">"
                                onChange={e => handlePagination(e)}
                            />
                        </div>
                        : null}
                </div>
            </div>
        </>
    )
}

export default AllVenues