import { useState, useEffect } from 'react';
import { NavigationBar } from '../Landing/NavigationBar';
import { useSearchParams, Link, useParams } from 'react-router-dom';
import { setRedirectURL } from '../../constants';
// import
import Footer from '../Footer';
import CustomMap from './CustomMap';
import location from '../../assets/images/loc.png';
import calender from '../../assets/images/cal.png';
import EDuration from '../../assets/images/e-duration.png';
import EAtten from '../../assets/images/e-atten.png';
import Ecall from '../../assets/images/e-call.png';
import prevIcon from '../../assets/images/prevIcon.png';
import nextIcon from '../../assets/images/nextIcon.png';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css/navigation';
import 'swiper/css';
import Location from '../../assets/images/Icons/location.svg';
import ApiClient from '../../methods/api/apiClient';
import methodModel from '../../methods/methods';
import moment from 'moment';
import loader from '../../methods/loader';
import { useSelector } from 'react-redux';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import SigninModal from '../Auth/SigninModal';

const EventDetails = ({ setIsSignup, thirdPartySignInClicked }) => {
    const { id } = useParams()
    const user = useSelector((state) => state.user)
    const [detail, setDetail] = useState({})
    const [categoryEvents, setCategoryEvents] = useState([])
    const [params] = useSearchParams()
    const [locations, setLocations] = useState([])
    const [mapView, setMapView] = useState({})

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        if (id) {
            getEventDetail()
        }
        if (params.get('redirectToPath')) {
            setRedirectURL(params.get('redirectToPath'));
        }
    }, [id])

    const getEventDetail = () => {
        loader(true)
        ApiClient.get(`event/detail?id=${id}`).then(res => {
            if (res.success) {
                const data = res?.payload
                setDetail(data)
                getCategoryEvents(data?.category?._id || data?.category?.id)
                setLocations([{ lat: data?.coordinates?.coordinates?.[1], lng: data?.coordinates?.coordinates?.[0], icon: methodModel.noImg(data?.images?.[0]), info: data?.title }])
                setMapView({ lat: data?.coordinates?.coordinates?.[1], lng: data?.coordinates?.coordinates?.[0], icon: methodModel.noImg(data?.images?.[0]), info: data?.title })
            }
            loader(false)
        })
    }

    const getCategoryEvents = (categoryId) => {
        if (categoryId) {
            ApiClient.get(`event/listing?category=${categoryId}`).then(res => {
                if (res.success) {
                    setCategoryEvents(res?.data?.filter((item) => item?._id !== id))
                }
            })
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const calculateDuration = (key) => {
        // Convert the input strings to Date objects
        const startDate = new Date(detail?.eventStartDate);
        const endDate = new Date(detail?.eventEndDate);
        // Calculate the difference in milliseconds
        const diffInMs = endDate.getTime() - startDate.getTime();
        // Calculate the difference in days, hours, and minutes
        const days = Math.floor(diffInMs / (1000 * 3600 * 24));
        const hours = Math.floor((diffInMs % (1000 * 3600 * 24)) / (1000 * 3600));
        const minutes = Math.floor((diffInMs % (1000 * 3600)) / (1000 * 60));
        // Return the results as an object
        if (key === "days") return (days || 0)
        else if (key === "hours") return (hours || 0)
        else if (key === "minutes") return (minutes || 0)
        else return ""
    }

    const handleLikeFollowEvent = (type) => {
        if (user?._id || user?.id) {
            const payload = {
                id: detail?.id || detail?._id,
                type: type,
                userId: user?._id || user?.id,
            }
            loader(true)
            ApiClient.post(`event/${type}`, payload).then(res => {
                if (res.success) {
                    getEventDetail()
                }
                loader(false)
            })
        } else {
            openModal()
        }
    }

    return (
        <>
            <NavigationBar />
            <div className="event-detail bg-[#D9D9D938] py-[40px]">
                <div className="w-[85%] mx-auto flex gap-9">
                    {/* <div className="flex flex-col max-w-[290px] pi-[20px] gap-3">
                        <p class="font-normal text-base leading-[30px] text-left text-[#1B1B25] font-poppins">
                            Share
                        </p>
                        <img
                            className="w-[50px] h-[50px] cursor-pointer"
                            src={InstaIcon}
                            alt=""
                        />
                        <img
                            className="w-[50px] h-[50px] cursor-pointer"
                            src={FBIcon}
                            alt=""
                        />
                        <img
                            className="w-[50px] h-[50px] cursor-pointer"
                            src={LinkIcon}
                            alt=""
                        />
                        <img
                            className="w-[50px] h-[50px] cursor-pointer"
                            src={TwtIcon}
                            alt=""
                        />
                    </div> */}
                    <div className="flex-1">
                        <img src={methodModel.noImg(detail?.images?.[0])} className='w-full h-[450px] object-cover' alt="event_image" />
                        <div className="flex py-10 flex-col md:flex-row gap-5 justify-between">
                            <div className="">
                                <h2 className="font-semibold text-3xl leading-[36.57px] text-left text-black font-montserrat capitalize">
                                    {detail?.title}
                                </h2>
                                <p className="flex items-center font-semibold text-base leading-[23.4px] tracking-[0.03em] text-left text-[#1B1B25] font-open-sans">
                                    <img className="mr-2 w-[18px] h-[18px]" src={location} alt="location_icon" />
                                    {detail?.location}
                                </p>
                                <p className="flex items-center font-semibold text-base leading-[23.4px] tracking-[0.03em] text-left text-[#1B1B25] font-open-sans">
                                    <img className="mr-2 w-[18px] h-[18px]" src={calender} alt="date_icon" />
                                    {moment(detail?.eventStartDate).format("DD MMM, YYYY hh:mm A")}
                                </p>
                                <p className="w-full max-w-[570px] font-normal text-base leading-[27.54px] tracking-[0.03em] text-left text-black font-open-sans" dangerouslySetInnerHTML={{ __html: detail?.short_description }}></p>
                                <div className="flex gap-[20px] pt-[25px]">
                                    <div onClick={e => handleLikeFollowEvent("follow")} className="flex items-center justify-center cursor-pointer text-[18px] text-nowrap font-semibold py-2 px-6 hover:text-black text-white border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-[16px] md:inline-flex">
                                        {detail?.follow_data?.some((item) => item?.addedBy?._id === user?._id) ? "Unfollow" : "Follow"}
                                    </div>
                                    <div onClick={e => handleLikeFollowEvent("like")} className={`flex cursor-pointer items-center text-[18px] text-nowrap font-semibold hover:bg-[#005AAB] hover:text-white text-black border py-2 px-6 border-[#005AAB] rounded-[16px] md:inline-flex`}>
                                        {detail?.like_data?.some((item) => item?.addedBy?._id === user?._id) ?
                                            <>
                                                <FaHeart className={`mr-2 text-red-600`} /> Unlike
                                            </>
                                            :
                                            <>
                                                <FaRegHeart className={`mr-2`} /> Like
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {detail?.isOnline &&
                                <div className="flex justify-end items-center">
                                    <div
                                        className="w-[285px] flex flex-col items-center border border-[black] px-[20px] py-[20px]"
                                        style={{
                                            boxShadow: '6px 8px 0px 0px #E1B524',
                                            borderRadius: '8px'
                                        }}
                                    >
                                        <p>Tickets starting at</p>
                                        <h3 className="font-montserrat text-lg font-semibold leading-6 text-left">
                                            ${parseFloat(detail?.price_of_ticket || 0).toFixed(2)}
                                        </h3>
                                        <button
                                            onClick={openModal}
                                            className="mt-5 w-full bg-[#005AAB] text-center font-poppins text-base font-semibold leading-7 text-left text-white py-[10px] px-[16px] gap-10 rounded-4"
                                        >
                                            Buy Tickets
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='mb-6'>
                            <h2 className="font-montserrat text-xl font-semibold leading-7 text-left text-gray-900">
                                Venue Amenities
                            </h2>
                            <div className="grid grid-cols-1 gap-8 mt-5 md:grid-cols-3">
                                {detail?.venue?.map((item, index) => {
                                    return <span key={index}>
                                        {item?.amenities?.map((itm, i) => {
                                            return <div className='flex gap-3 items-center' key={i}>
                                                <img src={methodModel.noImg(itm?.image)} className='w-[50px] h-[50px] object-cover rounded-full' />
                                                <span className='font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700'>{itm?.title || '--'}</span>
                                            </div>
                                        })}
                                    </span>
                                })}
                            </div>
                        </div>
                        <div>
                            <h2 className="font-montserrat text-xl font-semibold leading-7 text-left text-gray-900">
                                Event Information
                            </h2>
                            <div className="grid grid-cols-1 gap-8 mt-5 md:mt-10 md:grid-cols-3">
                                <div className="flex gap-[20px]">
                                    <img className="w-[45px] h-[45px]" src={EDuration} alt="duration_icon" />
                                    <div className="flex flex-col">
                                        <p className="font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                                            Duration
                                        </p>
                                        {/* <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                            20.00 - 21.56 WIB
                                        </span> */}
                                        <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                            {calculateDuration("days") !== 0 ? `${calculateDuration("days")} days ` : null}
                                            {calculateDuration("hours") !== 0 || calculateDuration("minutes") !== 0 ? `${calculateDuration("hours")} hours ` : null}
                                            {calculateDuration("minutes") !== 0 ? `${calculateDuration("minutes")} minutes` : null}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex gap-[20px]">
                                    <img className="w-[45px] h-[45px]" src={EAtten} alt="audience_icon" />
                                    <div className="flex flex-col">
                                        <p className="font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                                            Audience
                                        </p>
                                        <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                            {detail?.audience || '--'}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex gap-[20px]">
                                    <img className="w-[45px] h-[45px]" src={Ecall} alt="attention_icon" />
                                    <div className="flex flex-col">
                                        <p className="font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                                            Attention
                                        </p>
                                        <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                            {detail?.attention || '--'}
                                        </span>
                                    </div>
                                </div>
                                {detail?.host?.map((item, index) => {
                                    return <Link to="/hostProfile" className="flex gap-[20px]" key={index}>
                                        <img src={methodModel.noImg(item?.image)} className="w-[45px] h-[45px] rounded-full bg-gray-600" />
                                        <div className="flex flex-col">
                                            <p className="font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">Host</p>
                                            <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">{item?.fullName || item?.firstName}</span>
                                            <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">{item?.email}</span>
                                        </div>
                                    </Link>
                                })}
                            </div>
                            <div className="mt-10">
                                <h3 className="font-open-sans text-base font-semibold leading-6 tracking-tight text-left text-gray-900">Description</h3>
                                <p className="font-open-sans text-base font-normal leading-7 tracking-tight text-left text-black" dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                            </div>
                            <div className="w-full mx-auto mt-8">
                                <CustomMap mapView={mapView} locations={locations} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {categoryEvents?.length !== 0 &&
                <div className="w-[85%] mx-auto py-5 md:py-10">
                    <h2 className="font-montserrat text-2xl font-semibold leading-9 text-left text-[#2D2C3C] mb-2">
                        Other events you may like
                    </h2>
                    <div className="w-full">
                        <Swiper
                            className="mySwiper"
                            loop={true}
                            slidesPerView={1}
                            spaceBetween={10}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev'
                            }}
                            modules={[Navigation]}
                            breakpoints={{
                                1100: {
                                    slidesPerView: 3,
                                    spaceBetween: 60
                                }
                            }}
                        >
                            {categoryEvents && categoryEvents?.map((item, index) => {
                                return <SwiperSlide key={index}>
                                    <Link to={`/EventDetails/${item?._id || item?.id}`}>
                                        <div className="overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 mb-2">
                                            <img className="object-cover w-full h-[200px]" src={methodModel.noImg(item?.images?.[0])} alt="Article" />
                                            <div className="p-4">
                                                <h2 className="font-[500] text-[18px] capitalize">
                                                    {item?.title}
                                                </h2>
                                                <p className="font-[400] text-[14px]">
                                                    {moment(item?.eventStartDate).format("DD MMM, YYYY hh:mm A")}
                                                </p>
                                                <p className="flex gap-2 font-[400] text-[14px]">
                                                    <img src={Location} alt="location_icon" />
                                                    <span className='ellipsis_class text-[#585858] leading-[17px]'> {item?.location}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            })}
                            <div className="swiper-button-prev w-[40px] h-[40px] after:hidden">
                                <img className="w-full h-full" src={prevIcon} alt="Previous" />
                            </div>
                            <div className="swiper-button-next w-[40px] h-[40px] after:hidden">
                                <img className="w-full h-full" src={nextIcon} alt="Next" />
                            </div>
                        </Swiper>
                    </div>
                </div>
            }
            <Footer />
            <SigninModal isModalOpen={isModalOpen} closeModal={closeModal} setIsSignup={setIsSignup} thirdPartySignInClicked={thirdPartySignInClicked} />
        </>
    );
};
export default EventDetails;
